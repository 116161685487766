















































































































































import { Component, Vue } from 'vue-property-decorator';
import { Message } from 'element-ui';
import { claimOptions, defaultClaims } from '@/config/users';
import { all, create, remove, update, get, resetPassword } from '@/firebase/models/users';
import { UserModule } from '@/store/modules/user';

@Component
export default class UserList extends Vue {
  list = [];
  loading = true;
  saving = false;

  disabled = {};
  reset = {};
  popover: { [id: string]: boolean } = {};
  passwordLoading = {};
  disableLoading = {};
  editLoading = {};

  newUser = true;
  dialogActive = false;
  options = claimOptions;

  permissionOptions = claimOptions;

  user: any = {
    email: '',
    displayName: '',
    password: '',
    customClaims: defaultClaims,
  };

  rules = {
    email: [{ type: 'string', required: true, message: 'An email address must be specified', trigger: 'blur' }],
    displayName: [{ type: 'string', required: true, message: 'A name must be specified', trigger: 'blur' }],
    customClaims: [
      {
        type: 'object',
        required: true,
        message: 'At least one role must be selected',
        trigger: 'change',
      },
    ],
  };

  userEdit(id = '') {
    this.$set(this.editLoading, id, true);
    get(id).then((item: any) => {
      this.newUser = false;
      this.dialogActive = true;
      this.$set(this.editLoading, id, false);
      this.user = item.userRecord;
      this.user.customClaims = {
        defaultClaims,
        ...item.userRecord.customClaims,
      };
    });
  }

  userSave() {
    this.saving = true;
    if (this.newUser) {
      create(this.user).then((result: any) => {
        if (result.success) {
          Message({
            type: 'success',
            message: result.success,
          });
          this.dialogActive = false;
          this.fetchData();
        } else if (result.error) {
          Message({
            type: 'error',
            message: result.error,
          });
        } else {
          Message({
            message: 'Not sure what happened',
          });
        }
        this.saving = false;
      });
    } else {
      const payload = {
        uid: this.user.uid,
        data: {
          email: this.user.email,
          displayName: this.user.displayName,
        },
        customClaims: this.user.customClaims,
      };
      update(payload).then((result: any) => {
        this.dialogActive = false;
        this.saving = false;
        if (result.success) {
          Message({
            type: 'success',
            message: result.success,
          });
          this.fetchData();
        } else {
          Message({
            type: 'error',
            message: result.message,
          });
        }
      });
    }
  }

  userRemove(id: string) {
    remove(id).then((result: any) => {
      if (result.success) {
        Message({ type: 'success', message: result.success });
        this.fetchData();
      } else if (result.error) {
        Message({ type: 'error', message: result.error });
        this.fetchData();
      } else {
        Message({ message: 'Not sure what happened' });
      }
    });
  }

  resetPassword(email: string) {
    this.$set(this.passwordLoading, email, true);
    resetPassword(email).then((response: any) => {
      Message({
        type: 'success',
        message: response.success,
      });
      this.$set(this.passwordLoading, email, false);
    });
  }

  createUser() {
    this.user = {
      email: '',
      name: '',
      password: '',
      customClaims: defaultClaims,
    };
    this.newUser = true;
    this.dialogActive = true;
    // if (this.form) {
    //   this.form.resetFields();
    // }
  }

  disableUser(uid: string, status: boolean) {
    this.$set(this.disableLoading, uid, true);
    update({ uid, data: { disabled: !status } })
      .then((res: any) => {
        if (res.success) {
          Message({
            type: 'success',
            message: 'User has been ' + (status ? 'enabled' : 'disabled'),
          });
          this.$set(this.disableLoading, uid, false);
        } else if (res.error) {
          Message({
            type: 'error',
            message: res.error,
          });
        }
        this.fetchData();
      })
      .catch((error) => console.error(error));
  }

  confirmDelete(id: string) {
    Vue.set(this.popover, id, true);
    console.log(id, this.popover);
  }

  get isAdmin() {
    return UserModule.isAdmin;
  }

  closeDialog() {
    this.dialogActive = false;
  }

  created() {
    this.fetchData();
  }

  fetchData() {
    this.loading = true;
    all().then((response: any) => {
      this.list = response;
      for (const item of response) {
        Vue.set(this.popover, item.uid, false);
      }
      this.loading = false;
    });
  }
}
